import { useState, useCallback, useEffect } from 'react';
import { db, auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, addDoc, collection, updateDoc, onSnapshot, getDoc, getDocs } from "firebase/firestore";
import { loadStripe } from '@stripe/stripe-js';
import { Button, Spinner, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { Capacitor } from "@capacitor/core"
import {
  Purchases,
  LOG_LEVEL
} from '@revenuecat/purchases-capacitor';
import './css/Subscribe.css';
import * as RevenueCat from "@revenuecat/purchases-js";

import { accent_color, num_standard_messages, standard_price, premium_price, stripe_standard_price, stripe_premium_price } from "./variables.js"

let platform
let apiKey
if (Capacitor.isNativePlatform()) {
  platform = Capacitor.getPlatform();

  if (platform === "ios") {
    apiKey = "appl_EDUJmaBdRLifaBBLkTGBOljOhMn"
  }
  else if (platform === "android") {
    apiKey = "goog_bwoJsyGureYYVPVdLlgzADSUBSK"
  }
}
else {

}

const checkSubscription = (entitlements) => {
  let tier, status
  if (typeof entitlements.active["Standard"] !== "undefined") {
    tier = "Standard Plan";
    status = "active";
  }
  else if (typeof entitlements.active["Premium"] !== "undefined") {
    tier = "Premium Plan";
    status = "active";
  }
  else {
    tier = "No Plan";
    status = "inactive";
  }
  return { "subscribe_tier": tier, "subscribe_status": status }
}

export const getSubscribeInfo = async (user) => {

  const userRef = doc(db, "users", user?.uid);
  const userSnap = await getDoc(userRef);
  let usage, tier, status
  if (userSnap.exists()) {
    usage = userSnap.data()["standard_usage"];
  }
  if (Capacitor.isNativePlatform()) {
    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
    await Purchases.configure({
      apiKey: apiKey,
      appUserID: user.uid
    });
    const customerInfo = await Purchases.getCustomerInfo();

    if (typeof customerInfo.customerInfo.entitlements.active["Standard"] !== "undefined") {
      tier = "Standard Plan";
      status = "active";
    }
    else if (typeof customerInfo.customerInfo.entitlements.active["Premium"] !== "undefined") {
      tier = "Premium Plan";
      status = "active";
    }
    else {
      tier = "No Plan";
      status = "inactive";
    }
  }
  // web
  else {
    // check on web if user has an iOS/Android subscription
    RevenueCat.Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
    RevenueCat.Purchases.configure('rcb_SADZYqhWkDsjvnQVwurNTwfxWcMP', user.uid
    );

    const customerInfo = await RevenueCat.Purchases.getSharedInstance().getCustomerInfo();
    const subscriptionInfo = checkSubscription(customerInfo.entitlements)

    tier = subscriptionInfo.subscribe_tier
    status = subscriptionInfo.subscribe_status

    // if there is no iOS/android subscription, check if user has a stripe subscription
    if (status !== "active") {
      const subscriptionsRef = collection(db, "users", user?.uid, "subscriptions");
      const querySnapshot = await getDocs(subscriptionsRef);
      const subscriptions = [];
      querySnapshot.forEach((doc) => {
        subscriptions.push({ id: doc.id, ...doc.data() });
      });
      if (subscriptions.length > 0) {
        //Sort in descending order such that the most recent subscription is first
        subscriptions.sort((a, b) => b.created - a.created);
        tier = subscriptions[0].items[0].price.product.name;
        status = subscriptions[0].status;
      }
    }
  }
  return { "standard_usage": usage, "subscribe_tier": tier, "subscribe_status": status }

};

export const handleManageSubscription = async (setIsLoading, user, stripeId, backend_url, fetchWithTimeout) => {
  setIsLoading(true);
  const PortalOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ customer: stripeId }),
  };
  const portal_url = `${backend_url}/customer_portal`;
  try {
    const session_res = await fetchWithTimeout(portal_url, { timeout: 10000, ...PortalOptions });
    const customer_portal = await session_res.json();

    if (customer_portal.url) { window.location.href = customer_portal.url }
    else {
      setIsLoading(false)
      alert("An error occured, please try again in a few moments.")
    }
  } catch (e) {
    console.log(e)
    setIsLoading(false)
    alert("An error occured, please try again in a few moments.")
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
};

function Subscribe({ setShowSubscriptionModal, setSidebarOpen }) {
  const [user,] = useAuthState(auth);
  const [standardIsLoading, setStandardIsLoading] = useState(false);
  const [premiumIsLoading, setPremiumIsLoading] = useState(false);
  const mediaMatch = window.matchMedia('(max-width: 600px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const stripe_publishable_key = 'pk_live_51LxvhnKdUZsLS2B5WumgodLuCMt5veVGHdVllooE69RBreRKSMzxrf6QfDHZQl2vJM9oqqqHOjxHtqhEiJctNryt00ijZjdsMp'
  const [standardPrice, setStandardPrice] = useState("")
  const [premiumPrice, setPremiumPrice] = useState("")
  const [standardTrialPrice, setStandardTrialPrice] = useState("")
  const [premiumTrialPrice, setPremiumTrialPrice] = useState("")
  // annual pricing
  const [standardAnnualPrice, setStandardAnnualPrice] = useState("")
  const [premiumAnnualPrice, setPremiumAnnualPrice] = useState("")
  const [standardAnnualTrialPrice, setStandardAnnualTrialPrice] = useState("")
  const [premiumAnnualTrialPrice, setPremiumAnnualTrialPrice] = useState("")
  const [pricesLoading, setPricesLoading] = useState(true);
  const [key, setKey] = useState('annual');

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });

  useEffect(() => {
    const fetchPricing = async () => {
      const pricing = await getPricing('monthly')
      const pricingAnnual = await getPricing('annual')
      setStandardPrice(pricing.standard_price)
      setPremiumPrice(pricing.premium_price)
      setStandardTrialPrice(pricing.standard_trial_price)
      setPremiumTrialPrice(pricing.premium_trial_price)

      // annual pricing is displayed per month, divide by 12 and round to 2 decimal places
      const standardAnnualPrice = `$${(parseFloat(pricingAnnual.standard_price.replace('$', '')) / 12).toFixed(2)}`
      const premiumAnnualPrice = `$${(parseFloat(pricingAnnual.premium_price.replace('$', '')) / 12).toFixed(2)}`
      console.log(standardAnnualPrice)
      console.log(pricingAnnual.standard_price)
      setStandardAnnualPrice(standardAnnualPrice)
      setPremiumAnnualPrice(premiumAnnualPrice)
      setStandardAnnualTrialPrice(pricingAnnual.standard_trial_price)
      setPremiumAnnualTrialPrice(pricingAnnual.premium_trial_price)
      setPricesLoading(false)
    }
    fetchPricing()
  }, [])

  const handleSubscribe = useCallback(async (plan) => {
    let price
    if (plan === "standard") {

      price = stripe_standard_price
      const userRef = doc(db, "users", user?.uid);
      // this counts the number of sent messages in the current subscription period
      await updateDoc(userRef, { "standard_usage": 0 })
    }
    else if (plan === "premium") {

      price = stripe_premium_price
    }
    if (user) {
      const userRef = doc(db, "users", user.uid)
      const checkoutRef = collection(userRef, "checkout_sessions")

      const newSubscriptionRef = await addDoc(checkoutRef, {
        price: price,
        allow_promotion_codes: true,
        success_url: "https://my.serena.chat/dashboard",
        cancel_url: "https://my.serena.chat/dashboard"
      });
      onSnapshot(newSubscriptionRef, async (snap) => {
        const { sessionId } = snap.data();
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          const stripe = await loadStripe(stripe_publishable_key);
          stripe.redirectToCheckout({ sessionId });
          setStandardIsLoading(false);
          setStandardIsLoading(false);
        }
      });
    }
  }, [user]);

  const getPricing = async (renewal) => {
    try {
      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
      await Purchases.configure({
        apiKey: apiKey,
        appUserID: user.uid // Optional
      });


      let offerings;
      let retryCount = 0;
      const fetchOfferings = async () => {
        try {
          offerings = await Purchases.getOfferings();
        } catch (error) {
          if (retryCount < 4) {
            await new Promise(resolve => setTimeout(resolve, 200 * (retryCount + 1)));
            retryCount++;
            await fetchOfferings();
          } else {
            console.log("Failed to fetch offerings");
          }
        }
      };
      await fetchOfferings();

      let identifierStandard, identifierPremium
      if (renewal === 'monthly') {
        identifierStandard = 'Standard';
        identifierPremium = 'Premium';
      }
      else if (renewal === 'annual') {
        identifierStandard = 'Standard Annual';
        identifierPremium = 'Premium Annual';
      }

      if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
        const standardPackage = offerings.current.availablePackages.find(p => p.identifier === identifierStandard);
        const premiumPackage = offerings.current.availablePackages.find(p => p.identifier === identifierPremium);
        // if there is a trial price, return the trial price, otherwise return an empty string
        if (standardPackage.product.introPrice?.priceString && premiumPackage.product.introPrice?.priceString) {
          return {
            "standard_price": standardPackage.product.priceString,
            "premium_price": premiumPackage.product.priceString,
            "standard_trial_price": standardPackage.product.introPrice.priceString,
            "premium_trial_price": premiumPackage.product.introPrice.priceString
          }
        }
        else {
          return {
            "standard_price": standardPackage.product.priceString,
            "premium_price": premiumPackage.product.priceString,
            "standard_trial_price": "",
            "premium_trial_price": ""
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubscribeMobile = async (plan, renewal) => {
    if (plan === "standard") {
      setStandardIsLoading(true);
      const userRef = doc(db, "users", user?.uid);
      // this counts the number of sent messages in the current subscription period
      await updateDoc(userRef, { "standard_usage": 0 })
    }
    else if (plan === "premium") {
      setPremiumIsLoading(true);
    }
    try {
      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
      await Purchases.configure({
        apiKey: apiKey,
        appUserID: user.uid // Optional
      });

      //this allows to send events to Firebase, we need this to track renewal of subscriptions
      // await Purchases.shared.attribution.setFirebaseAppInstanceID("1:595110206151:ios:b2df2f3bd572bf3d98fce2")
      let identifierStandard, identifierPremium
      if (renewal === 'monthly') {
        identifierStandard = 'Standard';
        identifierPremium = 'Premium';
      }
      else if (renewal === 'annual') {
        identifierStandard = 'Standard Annual';
        identifierPremium = 'Premium Annual';
      }
      const offerings = await Purchases.getOfferings();
      if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
        const standardPackage = offerings.current.availablePackages.find(p => p.identifier === identifierStandard);
        const premiumPackage = offerings.current.availablePackages.find(p => p.identifier === identifierPremium);
        let selectedPackage
        if (plan === "standard") {
          selectedPackage = standardPackage
        }
        else if (plan === "premium") {
          selectedPackage = premiumPackage
        }

        await Purchases.purchasePackage({ aPackage: selectedPackage });
        setShowSubscriptionModal(false);
        setSidebarOpen(false);
        // await updateSubscribe(user, setStandardUsage, setSubscribeTier, setSubscribeStatus)
      }
      setStandardIsLoading(false);
      setPremiumIsLoading(false);
    } catch (error) {
      console.log(error)
      setStandardIsLoading(false);
      setPremiumIsLoading(false);
    }
  }

  const handleStandardClick = (renewal) => {
    setStandardIsLoading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        handleSubscribeMobile("standard", renewal);
      }
      else {
        handleSubscribe("standard");
      }
    } catch (err) {
      setPremiumIsLoading(false);
      setStandardIsLoading(false);
      console.log(err)
    }
  }
  const handlePremiumClick = (renewal) => {
    setPremiumIsLoading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        handleSubscribeMobile("premium", renewal);
      }
      else {
        handleSubscribe("premium");
      }
    } catch (err) {
      setPremiumIsLoading(false);
      setStandardIsLoading(false);
      console.log(err)
    }
  }

  const getTabTitleStyle = (tabKey) => ({
    color: key === tabKey ? 'black' : 'gray' // Change 'gray' to your desired unselected color
  });

  if (!matches) {
    // This is return on large displays 
    return (
      <div>
        <Row >
          <h4 className="text-start" style={{ width: "100%" }}>Limited time offer!</h4>
          <Col xs={6} className="d-flex flex-column" style={{ position: "relative", borderRight: "1px solid #dee2e6", height: "100%" }}>

            <h5>Standard <del>$9.99</del> ${standard_price}/mo</h5>
            <p>{num_standard_messages} messages</p>
            <Button
              variant="secondary"
              onClick={handleStandardClick}
              style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "220px", fontSize: "16px" }}
              disabled={standardIsLoading}
            >
              {standardIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Standard"}
            </Button>
            <div style={{ position: "absolute", top: "50%", left: "-1px", width: "1px", height: "50%", content: "", backgroundColor: "#dee2e6" }}></div>
          </Col>
          <Col xs={6} className="d-flex flex-column">
            <h5>Premium <del>$29.99</del> ${premium_price}/mo</h5>
            <p>Unlimited messages</p>
            <Button
              variant="secondary"
              onClick={handlePremiumClick}
              style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "220px", fontSize: "16px" }}
              disabled={premiumIsLoading}
            >
              {premiumIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Premium"}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
  else {
    // this is for mobile 

    return (
      <Tabs
        id="subscription-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 justify-content-center"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Tab eventKey="annual" title={<span style={getTabTitleStyle('annual')}>Annual</span>}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="w-100 mb-3 text-center">
              <h5 style={{ width: "100%" }}>Standard </h5>
              <p>{num_standard_messages} messages</p>
              {pricesLoading ? (
                <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                  <Spinner animation="border" size="sm" />
                </div>

              ) : (
                standardAnnualTrialPrice !== "" ? (
                  <p>1 month trial {standardAnnualTrialPrice}, then {standardAnnualPrice}/mo</p>
                ) : (
                  <p>{standardAnnualPrice}/mo</p>
                )
              )}

              <Button
                variant="secondary"
                onClick={() => handleStandardClick("annual")}
                style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "140px", height: "55px", fontSize: "14px" }}
                disabled={standardIsLoading}
              >
                {standardIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Standard"}
              </Button>
            </div>
            <div style={{ borderLeft: '1px solid #dee2e6', height: 'auto', margin: '0 20px' }} />
            <div className="w-100 mb-3 text-center">
              <h5>Premium </h5>
              <p>No message limit</p>
              {/* {premiumAnnualTrialPrice !== "" ? <p>1 month trial {premiumAnnualTrialPrice}, then {premiumAnnualPrice}/year</p> : <p>{premiumAnnualPrice}/year</p>} */}
              {pricesLoading ? (
                <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                  <Spinner animation="border" size="sm" />
                </div>

              ) : (
                premiumAnnualTrialPrice !== "" ? (
                  <p>1 month trial {premiumAnnualTrialPrice}, then {premiumAnnualPrice}/mo</p>
                ) : (
                  <p>{premiumAnnualPrice}/mo</p>
                )
              )}
              <Button
                variant="secondary"
                onClick={() => handlePremiumClick("annual")}
                style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "140px", height: "55px", fontSize: "14px" }}
                disabled={premiumIsLoading}
              >
                {premiumIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Premium"}
              </Button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="monthly" title={<span style={getTabTitleStyle('monthly')}>Monthly</span>}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="w-100 mb-3 text-center">
              <h5 style={{ width: "100%" }}>Standard </h5>
              <p>{num_standard_messages} messages</p>
              {standardTrialPrice !== "" ? <p>1 month trial {standardTrialPrice}, then {standardPrice}/mo</p> : <p>{standardPrice}/mo</p>}
              <Button
                variant="secondary"
                onClick={() => handleStandardClick("monthly")}
                style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "140px", height: "55px", fontSize: "14px" }}
                disabled={standardIsLoading}
              >
                {standardIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Standard"}
              </Button>
            </div>
            <div style={{ borderLeft: '1px solid #dee2e6', height: 'auto', margin: '0 20px' }} />
            <div className="w-100 mb-3 text-center">
              <h5>Premium </h5>
              <p>No message limit</p>
              {premiumTrialPrice !== "" ? <p>1 month trial {premiumTrialPrice}, then {premiumPrice}/mo</p> : <p>{premiumPrice}/mo</p>}
              <Button
                variant="secondary"
                onClick={() => handlePremiumClick("monthly")}
                style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "140px", height: "55px", fontSize: "14px" }}
                disabled={premiumIsLoading}
              >
                {premiumIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Premium"}
              </Button>
            </div>
          </div>
        </Tab>
      </Tabs>
    );

  }
}

export default Subscribe;